<template>
    <div class="scan">
        <p class="ma-0 white--text text-body-1 text-center scanner-explanation" :style="{position: 'absolute', top: `${componentHeaderHeight}px`}">{{$t("orderCheckExplain")}}</p>

        <ScannerCamera />

        <div class="target-camera full-height">
            <v-img max-width="300" max-height="300" src="@/assets/img/cam-overlay.png" />
        </div>
        <div class="py-3 d-flex justify-center align-center check-order-footer full-width">
            <v-btn fab elevation="0" dark color="primary" width="64" height="64" @click="$router.push({ name: 'Home' })">
                <v-img width="32" height="32" src="@/assets/img/icon-home-white.png" contain></v-img>
            </v-btn>
        </div>
        <audio id="beep" ref="beep" controls hidden>
            <source src="@/assets/sounds/beep_100_0_1.wav" type="audio/wav">
            {{$t('errorSound')}}
        </audio>
    </div>
</template>

<script>
    import ScannerCamera from "@/components/subcomponents/ScannerCamera.vue";
    let Barcode = require("@/assets/js/Barcode");
    import {mapActions, mapState} from 'vuex'

    export default {
        name: 'ScanOrder',
        components: {
            ScannerCamera
        },
        data() {
            return {
                cameras: [],
                cameraToUse: null,
                paymentStarted: false,
                error: false,
                explainTextStyle: "",
                componentHeaderHeight: 0
            }
        },
        created() {

        },
        mounted() {
            Barcode.start(this.onDecode)
            this.changeCurrentPage(this.$route.meta.page)
            // this.getCameras()
        },
        computed:{
            ...mapState('header', ['currentPage'])
        },
        methods: {
            // async getCameras() {
            //     this.cameras = await Barcode.getCameras()
            // },

            async onDecode(decodedString) {
                console.log("Scan order")
                if(this.$route.name == "ScanOrder"){
                    let basketComplexId = decodedString.codeResult.code;
                    this.showLoader({
                        message: this.$t("scanTerminalLoaderText"),
                        color: this.$vuetify.theme.themes.light.primary
                    })
                    try {
                        await this.getBasketByComplexId(basketComplexId)
                        Barcode.reset()
                        this.hideLoader()
                        setTimeout(() => {
                            this.$router.push({
                                name: "CheckProducts"
                            })
                        }, 500);
                    } catch (e) {
                        this.hideLoader()
                        this.showSnackbar({
                            message: "Une erreur est survenue",
                            type: "error",
                            timeout: 5
                        })
                    }
                }
            },
            calculateHeaderHeight(){
                return document.getElementById("app-header").offsetHeight + 48 || 0
            },
            ...mapActions('header', [ 'changeCurrentPage' ])
        },
        beforeRouteLeave(to, from, next) {
            /* eslint-disable */
            console.log("before route leave")
            Barcode.reset()
            next();
        },
        watch: {
            cameras() {
                Barcode.reset()
                this.cameraToUse = this.cameras[0]
            },
            cameraToUse(camera) {
                Barcode.reset()
                Barcode.start(camera.device, this.onDecode);
            },
            currentPage(){
                this.componentHeaderHeight = this.calculateHeaderHeight();
            }
        }

    };
</script>
<style scoped>
    .scan {
        height: 100%;
        /*height: calc(var(--vh, 1vh) * 100);*/
        background: black;
        position: relative;
    }

    .target-camera {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .scanner-explanation {
        width: 100%;
        z-index: 10;
    }

    .check-order-footer {
        position: absolute;
        bottom: 0;
    }
</style>
